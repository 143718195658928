import React, { useState, useEffect } from "react";
import { Space, Table, PageHeader, Button, message, Popconfirm } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios, { Axios } from "axios";

import Loader from "../../components/loader";
import Cookies from "js-cookie";
import "../../styles/main.sass";
import "antd/dist/antd.css";

const columns = [
  {
    title: "Nome",
    dataIndex: "nome",
    key: "nome",
    render: (text) => (
      <span
        style={{ color: "grey" }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></span>
    ),
  },
  {
    title: "Nome Gruppo",
    dataIndex: "nomeGruppo",
    key: "nomeGruppo",
  },

  {
    title: "Istituto",
    dataIndex: "istituto",
    key: "istituto",
  },
  {
    title: "Programma",
    dataIndex: "programma",
    key: "programma",
  },
  {
    title: "Email Genitore",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Numero partecipanti",
    dataIndex: "numeroPartecipanti",
    key: "numeroPartecipanti",
  },
  {
    title: "Voto",
    dataIndex: "voto",
    key: "voto",
    sorter: (a, b) => a.voto - b.voto,
  },
];

const columnsSolisti = [
  {
    title: "Nome",
    dataIndex: "nome",
    key: "nome",
    render: (text) => (
      <span
        style={{ color: "grey" }}
        dangerouslySetInnerHTML={{ __html: text }}
      ></span>
    ),
  },
  {
    title: "Istituto",
    dataIndex: "istituto",
    key: "istituto",
  },
  {
    title: "Programma",
    dataIndex: "programma",
    key: "programma",
  },
  {
    title: "Email Genitore",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Voto",
    dataIndex: "voto",
    key: "voto",
    sorter: (a, b) => a.voto - b.voto,
  },
];

function OnlineTable({ location }) {
  const [sezione, setSezione] = useState(false);
  const [categoria, setCategoria] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [published, setPublished] = useState(false);

  const params = new URLSearchParams(location.search);
  const sez = params.get("sez");
  const cat = params.get("cat");

  useEffect(() => {
    if (!Cookies.get("cmac")) {
      console.log("si");
      window.location.href = "/dashboard/login";
    }

    axios
      .post(
        "https://concorsopaoloferro.edu.it/concorso-api/dashboard/offline-table.php",
        {
          sezione: sez,
          categoria: cat,
        }
      )
      .then((result) => {
        setLoading(false);
        console.log(result.data);
        setData(result.data.dati);
        setPublished(result.data.pubblicati);
      })
      .catch((error) => {
        setLoading(false);
        return null;
      });
  }, []);

  if (loading) return <Loader />;

  function confirm(azione) {
    axios
      .post(
        "https://concorsopaoloferro.edu.it/concorso-api/dashboard/pubblica-rimuovi-risultati.php",
        {
          azione: azione,
          tipo: "offline",
          sezione: sez,
          categoria: cat,
        }
      )
      .then((result) => {
        console.log(result.data);
        if (result.data == 0) {
          message.success("risultati rimossi");
          setPublished(false);
        } else if (result.data == 1) {
          message.success("risultati pubblicati");
          setPublished(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        return null;
      });
  }

  return (
    <>
      <PageHeader
        style={{
          backgroundColor: "#f7f7f7",
          borderBottom: "1px solid black",
        }}
        ghost={false}
        onBack={() =>
          (window.location.href = "/dashboard/offline-table-select")
        }
        title="Categorie"
        extra={[
          published ? (
            <Popconfirm
              placement="topLeft"
              title={"Sicuro di volerli rimuovere?"}
              onConfirm={() => confirm("rimuovi")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="secondary">Nascondi i risultati</Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              placement="topLeft"
              title={"Sicuro di voler pubblicare?"}
              onConfirm={() => confirm("pubblica")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Pubblica i risultati</Button>
            </Popconfirm>
          ),
        ]}
      ></PageHeader>
      {sez > 1 ? (
        <Table columns={columns} dataSource={data} />
      ) : (
        <Table columns={columnsSolisti} dataSource={data} />
      )}
    </>
  );
}

export default OnlineTable;
